.intro {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  color: $c-yellow;
  position: relative;
  text-align: center;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    height: 100svh;
  }

  &.is-safari {
    height: 100svh;
  }

  .bottom {
    &.is-inview {
      h1, .block-arrow, .group-btn {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  h1 {
    padding-left: 10px;
    padding-right: 10px;
    color: $c-yellow;
    position: relative;
    z-index: 10;
    text-transform: uppercase;
    font-family: $f-secondary, sans-serif;
    margin-bottom: 20px;
    font-style: italic;
    @include fluid-type(325px, 1400px, 26px, 60px);
    line-height: 1.3;
    transition: opacity .6s cubic-bezier(.455, .03, .515, .955) .11s, transform 1.2s cubic-bezier(.165, .84, .44, 1) .11s;
    opacity: 0;
    transform: translateY(40px);

    span {
      margin-top: 10px;
      display: block;
      line-height: 1.3;
      @include fluid-type(375px, 991px, 20px, 30px);

    }

  }

  .group-btn {
    margin-bottom: 20px;
    z-index: 14;
    position: relative;
    opacity: 0;
    transform: translateY(40px);
    transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform 1.2s cubic-bezier(.165, .84, .44, 1);
    display: flex;
    align-content: center;
    justify-content: center;
    //padding-left: 15px;
    gap: 15px;

    a {
      text-decoration: none;
      color: $c-purple;
      font-family: $f-primary;
      padding: 8px 20px;
      background: $c-yellow;
      border-radius: 20px;
      border: 2px solid $c-purple;
      display: inline-block;
      font-weight: $semi-bold;
      transition: border-color 500ms ease, color 500ms ease, background 500ms ease;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
      @include fluid-type(325px, 474px, 14px, 16px);

      &:hover {
        background: $c-purple;
        color: $c-yellow;
      }
    }
  }

  .block-arrow {
    transition: opacity .6s cubic-bezier(.455, .03, .515, .955) .11s, transform 1.2s cubic-bezier(.165, .84, .44, 1) .11s;
    opacity: 0;
    top: 10px;
    transform: translateY(40px);
    position: relative;
    z-index: 10;

    img {
      max-width: 24px;
    }
  }

  .linear {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(16, 1, 37, 1) 100%);
    opacity: 0;

    transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform 1.2s cubic-bezier(.165, .84, .44, 1);

    &.is-inview {
      opacity: 1;
    }
  }

  .bounce {
    z-index: 10;
    position: relative;
  }

  @include media-breakpoint-down(md) {
    background-position: 33% 0;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    background-position: 100% 0;
  }
  @media screen and (min-width: 723px) and (max-width: 726px) and (min-height: 702px) and (max-height: 707px) {
    background-position: 33% 0;
  }
  @media screen and (max-height: 485px) and (orientation: landscape) {
    min-height: 100svh;
    height: auto !important;
    padding-top: 160px;
    position: relative;
  }
  //background-image: url('../../img/image-fond.jpg');
}
