.popup-mention {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $c-yellow;
  transition: opacity 500ms ease, visibility 500ms ease;
  visibility: hidden;
  opacity: 0;

  .inner {
    font-family: $f-primary;
    color: $c-purple;
    margin: 0 auto;
    line-height: 1.2;

    height: 100%;
    overflow: scroll;
    position: relative;
    padding: 60px 20px;

    .content {
      margin: 0 auto;
      max-width: 800px;
    }

    .liste {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;

      li {
        padding: 0 10px;
      }
    }
  }

  h2 {
    text-align: center;
    font-size: 25px;
    font-weight: $bold;
    text-transform: uppercase;
  }

  h3 {
    font-size: 18px;
    font-weight: $bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  strong {
    font-weight: bold;
  }

  p, span {
    display: block;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.3;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .icon-close {
    cursor: pointer;
    z-index: 30;
    position: absolute;
    right: 10px;
    top: 10px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}
