@import "utils/reset";
@import "utils/mixins";
@import "configs/fonts";
@import "configs/variables";
@import "configs/global";
@import "libs/slicks";
@import "libs/klaro";
@import "components/lenis";
@import "components/header";
@import "components/title";
@import "components/loader";
@import "components/popup";
@import "components/mouse";
@import "components/screenrotation";
@import "components/slide-temoins";
@import "components/block-animation-text";
@import "components/footer";
@import "sections/section-sticky";
@import "sections/section-jouer";
@import "sections/section-intro";
@import "sections/section-tinder";
@import "sections/section-dessin";
@import "sections/section-score";
@import "sections/section-discover";


html {
  .overflow {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.fake-link {
  display: none;
}
