.slider-metier-container {
  padding-left: 5vw;
  padding-right: 5vw;

  margin: 0 auto;
  padding-bottom: 60px;
  transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform .8s cubic-bezier(.165, .84, .44, 1);

  &.is-inview {
    opacity: 1;
    transform: translateY(0);
  }

  .slider-metier {
    max-width: 1136px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .slick-slide {
      transition: opacity 500ms ease;

      &:not(.slick-current) {
        opacity: .6;
      }

      &.slick-active {
        opacity: 1;
      }

      > div, .item-slide, .inner {
        height: 100%;
      }
    }
  }

  .slick-slide {
    &.slick-active, &.slick-current, &.slick-center {
      .item-slide {
        .inner {
          z-index: 0;
        }
      }
    }
  }

  .item-slide {
    padding: 15px;
    box-sizing: border-box;
    cursor: auto;

    &:hover {
      .block-image {
        img {
          transform: scale(1.2);
        }
      }
      .btn {
        //background: $c-yellow!important;
        //color: $c-purple!important;

      }

    }

    .block-image {
      position: relative;
      overflow: hidden;

      img {
        transition: transform 500ms ease;
      }
    }

    .inner {
      box-sizing: border-box;
      background: $c-white;
      box-shadow: 7px 7px 5px rgba(0, 0, 0, .6);
      border-radius: 20px;
      text-align: center;
      padding: 25px 35px 30px;
      display: flex;
      flex-flow: column;
      position: relative;
      z-index: -1;

      .title {
        font-size: 22px;
        line-height: 1.2;
        font-family: $f-primary;
        font-weight: $bold;
        color: $c-black;
        font-style: italic;
        margin-bottom: 10px;
      }

      .block-image {
        img {
          display: block;
          max-width: 100%;
          height: auto;
          width: 100%;
        }
      }

      .block-description {
        margin-bottom: 60px;
        box-sizing: border-box;
        display: block;
        margin-top: 39px;

        p {
          font-family: $f-primary;
          font-weight: $medium;
          font-size: 23px;
          @include fluid-type(375px, 1400px, 16px, 23px);
          line-height: 1.17;
        }
      }

      .btn {
        box-sizing: border-box;
        font-family: $f-primary;
        font-weight: $bold;
        font-size: 20px;
        line-height: 1.15;
        text-decoration: none;
        background: $c-purple;
        color: $c-white;
        padding: 10px 15px;
        display: block;
        border-radius: 20px;
        transition: background 500ms ease, color 500ms ease;
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;

        &:hover {
          background: $c-yellow;
          color: $c-purple;
        }
      }
    }
  }

  .slick-dots {
    padding-top: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    li {
      margin: 0 7px;

      button {
        @include input-no-appearance;
        font-size: 0;
        border-radius: 100%;
        display: inline-block;
        width: 28px;
        height: 28px;
        border: 4px solid $c-purple;
        background: $c-yellow;
        cursor: pointer;
        transition: background 500ms ease, opacity 500ms ease;

        &:hover {
          opacity: 1;
        }
      }

      &.slick-active {
        button {
          opacity: 1;
          background: $c-purple;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .item-slide {
      .inner {
        padding: 25px 20px 40px;

        .block-description {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }


  }
  @include media-breakpoint-down(xs) {
    padding-left: 0;
    padding-right: 0;
    .slick-list {
      padding-left: 14% !important;
      padding-right: 14% !important;
    }

  }
  @media screen and (max-width: 400px) {
    .slick-list {
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
  }
}
