.mouse {
  position: fixed;
  //bottom: 4%;
  //left: 50%;
  bottom: 80px;
  left: calc(50% - 13px);
  cursor: ns-resize;
  //transform: translateX(-50%);

  pointer-events: none;
  transition: opacity 500ms ease;

  &.active {
    opacity: 0;
  }

  &-shape {
    position: relative;
    display: block;
    width: 35px;
    height: 65px;
    margin: 0 auto 15px;
    border: 3px solid #FFF;
    border-radius: 22px;

    .mouse-animation {
      position: absolute;
      display: block;
      top: 25%;
      left: 50%;
      width: 8px;
      height: 8px;
      background: #FFF;
      border-radius: 50%;
      transform: translate(-50%, -25%);
      animation: ball-scroll 2s linear infinite;
    }
  }

  .mouse-msg {
    font-size: .8rem;
  }
}

@keyframes ball-scroll {
  0% {
    opacity: 1;
    top: 25%;
  }
  20% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 75%;
  }
  100% {
    opacity: 0;
    top: 25%;
  }
}

.block-refresh {
  z-index: 10;
  display: block;
  position: relative;
  text-align: center;
  padding-top: 20px;

  a {
    display: block;
    box-sizing: border-box;
    font-family: $f-primary;
    font-weight: $bold;
    font-size: 20px;
    line-height: 1.15;
    text-decoration: none;
    background: $c-purple;
    color: $c-white;
    border: 1px solid $c-purple;
    padding: 12px 30px;
    border-radius: 30px;
    transition: background 500ms ease, color 500ms ease;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;

    &:hover {
      background: $c-yellow;
      color: $c-purple;
    }
  }
}

body {
  &.mobile {
   .section1 {
     .mouse {
       display: none;
     }
     .block-scroll {
       opacity: 1;
     }
   }
  }
}
