#dessine-univers {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
  background: $c-purple;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .title {
    padding: 0 15px;
    @include fluid-type(575px, 1920px, 32px, 75px);

    color: $c-yellow;
    text-transform: uppercase;
    line-height: 1;
    //font-family: $f-secondary;
    font-family: $f-secondary-italic;
    font-style: italic;
    text-align: center;
    position: relative;
    z-index: 2;
    opacity: 0;
    transform: translateY(40px);
    transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform .8s cubic-bezier(.165, .84, .44, 1);

    span {
      display: block;
      margin-top: 10px;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    padding-top: 80px;

    .univers {
      width: 100%;
      max-width: 1320px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 20px 10px 20px 10px;
      margin: auto 0;

      &__card {
        width: calc(100%/4);
        min-width: 170px;
        max-width: 330px;
        padding: 15px;

        &:hover {
          cursor: pointer;
        }

        @media (max-width: 991px) {
          width: calc(100%/2.5);
        }

        &__inner {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          figure {
            box-shadow: 4px 4px 10px 3px rgba(0,0,0,0.64);
            width: 100%;
            padding-bottom: 100%;
            position: relative;
            border-radius: 20px;
            overflow: hidden;

            &:hover {
              box-shadow: 4px 4px 10px 3px rgba(0,0,0,1);
            }

            img {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: all ease .2s;
              border-radius: 20px;

              &.active {
                border: 4px solid $c-yellow;
              }

              &[data-category="nature"] {
                object-position: bottom;
              }
            }
          }
        }

        &__title {
          color: $c-yellow;
          text-transform: uppercase;
          line-height: 1.2;
          font-family: $f-secondary;
          text-align: center;
          padding-bottom: 14px;
          margin-top: auto;
          @include fluid-type(575px, 1440px, 16px, 20px);

          br {
            @media (min-width: 1200px) {
              display: none;
            }
          }
        }
      }
    }

    &.is-inview {
      .title, .group-btn {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .group-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform .8s cubic-bezier(.165, .84, .44, 1);
    opacity: 0;
    transform: translateY(40px);
    //margin-top: auto;
    margin-bottom: auto;

    .btn {
      margin: 0 auto;
      text-decoration: none;
      color: $c-purple;
      font-family: $f-primary;
      padding: 8px 20px;
      background: $c-yellow;
      border-radius: 20px;
      border: 2px solid $c-purple;
      display: inline-block;
      font-weight: $semi-bold;
      transition: border-color 500ms ease, color 500ms ease, background 500ms ease;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
      @include fluid-type(325px, 474px, 14px, 16px);

      &:hover {
        background: $c-purple;
        color: $c-yellow;
      }
    }
  }

  .wrapper-dessin {
    position: absolute;
    left: 0;
    right: 0;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    background-color: $c-purple;
    z-index: 2;
    transition: all 500ms ease;

    &__inner {
      width: 100%;
      height: 100%;
      position: relative;
      margin: 0 auto;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

      @media (min-width: 575px) {
        max-width: (100vh * 0.56);
      }

      &__back {
        width: 45px;
        height: 35px;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 10;

        path {
          stroke: $c-yellow;
        }
      }
    }

    &.is-inview {
      .title, .group-btn {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:not(.active) {
      opacity: 0;
      pointer-events: none;
    }

    &__bg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      object-fit: cover;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    #drawingCanvas {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 4;
      background-color: transparent;
    }

    .linear {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      opacity: 1;
      z-index: 3;
      transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform 1.2s cubic-bezier(.165, .84, .44, 1);
      padding-bottom: 35px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(16, 1, 37, 1) 100%);
        opacity: 1;
        transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform 1.2s cubic-bezier(.165, .84, .44, 1);
      }
    }

    &.numerique {
      .linear {
        &:after {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #330173 8%);
          opacity: 0.4;
        }
      }
    }
    &.climat {
      .linear {
        &:after {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000d4e 8%);
          opacity: 0.7;
        }
      }
    }
    &.nature {
      .linear {
        &:after {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #330173 8%);
          opacity: 0.4;
        }
      }
    }
    &.ville {
      .linear {
        &:after {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #21004b 8%);
          opacity: 0.5;
        }
      }
    }

    .title {
      position: relative;
      line-height: 1.15;
      z-index: 5;
      font-size: 32px;

      &:not(.sub-title) {
        padding-top: 30px;
      }

      span {
        margin-top: 0;
      }

      &.sub-title {
        font-size: 24px;
        margin-top: 4px;

        .light {
          font-family: $f-primary;
        }

        .yellow {
          height: 25px;
          margin-bottom: -4px;
          margin-right: -6px;
        }

        & > * {
          display: inline-block;
        }
      }
    }
    .group-btn {
      z-index: 5;
      position: absolute;
      bottom: 0;

      [data-action="screenshot"] {
        position: relative;

        svg {
          pointer-events: none;
          width: 55px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -110%);
          opacity: 0;
          transition: opacity .6s cubic-bezier(.455, .03, .515, .955);
        }

        &.load {
          pointer-events: none;

          svg {
          opacity: 1;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
  }
}
