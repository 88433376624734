.section1 {
  height: 400vh;
  position: relative;
  z-index: 11;

  .section-sticky {
    overflow: hidden;
  }

  .title-splite {
    position: absolute;
    top: 25vh;
  }

  .full-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  .sequence-images {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    z-index: 1;

    img {
      //opacity: 0;
    }
  }

  .section-sticky {
    position: sticky;
    top: 0;
    height: 100vh;
  }

  .sequence-body {
    pointer-events: none;
    //opacity: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    box-sizing: border-box;

    img {
      //height: 53vh;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  img {
    object-fit: cover;
  }

  .trigger {
    width: 30px;
    height: 90vh;
    right: 0;
    position: absolute;

    &-1 {
      top: 10vh;
    }

    &-2 {
      top: 110vh;
    }

    &-3 {
      top: 220vh;
    }

    &-4 {
      height: 99vh;
      bottom: 0;
    }
  }


}

.block-scroll {
  position: fixed;
  bottom: 30px;
  left: calc(50% - 10px);
  transition: opacity 500ms ease;
  //opacity: 0;

  &.active {
    opacity: 0!important;
  }
  .scroll {
    display: block;
    width: 30px;
    height: auto;
    object-fit: contain;
    //filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    position: relative;
  }


}
.bounce {
  animation: bounce-scroll 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@keyframes bounce-scroll {
  50% {
    transform: translateY(-15px);
  }
}
