.title-splite {
  text-transform: uppercase;
  width: 100%;
  z-index: 1;
  font-size: 80px;
  color: $c-yellow;
  text-transform: uppercase;
  line-height: 1;
  font-family: $f-secondary, sans-serif;
  //font-weight: bold;
  //font-family: $f-secondary-italic, sans-serif;
  //font-style: italic;
  text-align: center;
  top: 25vh;
  @include fluid-type(375px, 991px, 40px, 80px);
  //letter-spacing:6.2px;  // ???
  //-webkit-text-rendering: optimizeSpeed;
  //text-rendering: optimizeSpeed;
  //-webkit-transform: translateZ(0);
  //transform: translateZ(0);

  &.c-purple {
    color: $c-purple;
  }

  div, span {
    &::selection {
      background: transparent;
    }
  }

  > div {
    //padding: 0 15px;
    //font-family: $f-secondary-italic, sans-serif;
    //
    //font-style: italic;

    > div {
      //font-family: $f-secondary-italic, sans-serif;
      //
      //font-style: italic;
      //display: inherit!important;
    }
  }
}
