.loader {
  position: fixed;
  top: 0;
  left: 0;
  background: $c-yellow;
  z-index: 100;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform 1.2s cubic-bezier(.165, .84, .44, 1);
  .inner {
    font-family: $f-primary;
    color: $c-purple;

    p {
      margin-top: 20px;
      font-size: 20px;
    }

    .percentage {
      //font-size: 80px;
      @include fluid-type(375px, 991px,40px,80px);
    }
  }
  &:not(.loading) {
    transform: translateY(-100%);
  }
}
