.phone-screen {
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background: $c-yellow;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: none;

  .phone {
    height: 50px;
    width: 100px;
    margin: 0 auto 60px auto;
    border: 3px solid $c-purple;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* display: none; */
  }

  .message {
    font-family: $f-primary;
    font-size: 30px;
    margin-top: 40px;
    color: $c-purple;
    text-align: center;
    /* display: none; */
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }
    50% {
      transform: rotate(-90deg)
    }
    100% {
      transform: rotate(-90deg)
    }
  }

}
@media only screen and (max-device-width: 933px) and (orientation: landscape) {
  .phone-screen {
    display: flex;
    .phone, .message {
      display: block;
    }
  }
}
