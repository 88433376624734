#jouer {
  background: $c-yellow;
  position: relative;
  z-index: 10;
  .block-animation-text {
    &.last {

    }
  }
  .block-last {
    min-height: 35vh;
  }
}
