.discover {
  padding-top: 40px;
  padding-bottom: 40px;
  background: $c-yellow;
  text-align: center;
  font-family: $f-secondary;
  text-transform: uppercase;
  font-size: 23px;
  @include fluid-type(375px, 1400px, 16px, 23px);
  color: $c-purple;
  line-height: 1.17;
  display: none;

  .inner > a {
    text-decoration: none;
    transition: opacity 500ms ease, box-shadow 500ms ease;
    color: $c-purple;
    box-shadow: 0 -1px 0 inset $c-purple;

    &:hover {
      box-shadow: 0 0px 0 inset $c-purple;
    }

    &:hover {
      opacity: .8;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-top: 0;
  }
}
