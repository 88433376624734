$f-primary: "ITC Avant Garde Gothic Std";
$f-secondary: "ITC Avant Garde Gothic Std Demi";
$f-secondary-italic: "ITC Avant Garde Std Bk";

$thin:          100;
$extralight:    200;
$light:         400;
$regular:       500;
$medium:        500;
$semi-bold:     600;/* Equivalent medium Gotham */
$bold:          700;
$extra:         900;

$c-white: #FFFFFF;
$c-black: #000000;
$c-yellow: #FDEB2C;
$c-purple: #370972;
$c-green: #2ecc71;
