.block-animation-text {
  min-height: 70vh;
  text-align: center;
  position: relative;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .title-splite {
    position: sticky;
    top: 42vh;

  }
  @include media-breakpoint-down(sm) {
    min-height: 80vh;
    .title-splite {
      //top: 20vh;
    }
  }
}
