#futur-metier {
  min-height: 100vh;
  background: $c-purple;
  box-sizing: border-box;
  padding-top: 80px;
  padding-bottom: clamp(150px, 8vw, 8vw);
  position: relative;

  overflow: hidden;


  body {
    background-color: #35647e;
  }

  .promptBox {
    height: 100px;
    width: 100px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 48%;
    left: 50%;
    opacity: 1;
    transition: 300ms;
    pointer-events: none;
    z-index: 1000;
    //transition: opacity 500ms ease;
    &.disabled {
      opacity: 0;
    }

  }

  .tapperoo {
    height: 48px;
    width: 100px;
    position: absolute;
    top: -5px;
    left: 50px;
    border-radius: 100px;
    background-color: $c-white;
    box-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
    z-index: -1;
    animation: tapperoo 3s infinite;
  }

  @keyframes tapperoo {
    0% {
      height: 25%;
      width: 25%;
    }

    50% {
      height: 5%;
      width: 35%;
      opacity: 0;
      transform: translate(-250%);
    }

    100% {
      opacity: 0;
    }
  }

  #tap-gesture {
    position: absolute;
    z-index: 4;
    transform: rotate(30deg);
    animation: handMove 3s infinite;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }

  @keyframes handMove {
    0% {
      transform: rotate(30deg);
    }

    50% {
      transform: translate(-60%, -10%) rotate(-30deg) scale(0.9);
    }

    100% {
      transform: rotate(30deg);
    }
  }


  .inner {
    .title {
      //font-size: 125px;
      @include fluid-type(375px, 991px, 40px, 90px);
      @media screen and (orientation: landscape) {
        @include fluid-type(375px, 1200px, 25px, 90px);

      }
      padding: 0 15px;

      color: $c-yellow;
      text-transform: uppercase;
      line-height: 1;
      //font-family: $f-secondary;
      font-family: $f-secondary-italic;
      font-style: italic;
      text-align: center;
      position: relative;
      z-index: 2;
      opacity: 0;
      transform: translateY(40px);
      transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform .8s cubic-bezier(.165, .84, .44, 1);

      .target {
        position: absolute;
        left: 0;
        top: -50px;
        width: 10px;
        height: 10px;
        opacity: 0;

      }


      span {
        display: block;
        margin-top: 10px;
      }
    }

    .block-tinder {
      //min-height: 400px;
      position: relative;
      padding-top: 2.3vw;
      opacity: 0;
      transform: translateY(40px);
      transition: opacity .6s cubic-bezier(.455, .03, .515, .955) .11s, transform .8s cubic-bezier(.165, .84, .44, 1) .11s;

      .blob {
        pointer-events: none;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-down(xs) {
          width: 115%;
          top: 70%;
        }

      }
    }

    &.is-inview {
      .title, .block-tinder {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .tinder {
    z-index: 10;
    position: relative;
    max-width: 530px;
    margin: 0 auto;

    .tinder-cards {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      z-index: 1;
      height: 78vw;
      max-height: 350px;
      //opacity: 0;

      //z-index: 11; transform: translate(-6px, -6px); opacity: 1; pointer-events: none;
      @for $i from 1 through 9 {
        &:nth-child(#{$i}) {
          z-index: 25;
        }
      }
      @media (max-height: 675px) {
        min-height: 36vh;
        //margin-top: -15vh;
      }

      .tinder-card {
        display: inline-block;
        width: calc(68vw - 80px);
        height: calc(68vw - 80px);
        max-width: 300px;
        max-height: 300px;
        //overflow: hidden;
        position: absolute;
        will-change: transform;
        transition: all 0.3s ease-in-out;
        cursor: grab;
        border-radius: 20px;
        box-shadow: 7px 7px 5px rgba(0, 0, 0, .4);
        //box-shadow: $b-shadow;

        @media screen and (max-height: 675px) and (orientation: portrait) {
          width: calc(35vh - 40px);
          height: calc(35vh - 40px);
        }
        @media screen and (max-height: 675px) and (orientation: landscape) {
          width: calc(40vw - 80px);
          height: calc(40vw - 80px);
        }

        &.moving {
          transition: none;
          cursor: grabbing;
        }

        div {
          font-size: 40px;
          pointer-events: none;
          color: black;

          &.small {
            font-size: 32px;
          }

          @media (max-width: 600px), (max-height: 700px) {
            font-size: 31px;

            &.small {
              font-size: 25px;
            }
          }
        }

        &.removed {
          opacity: 0 !important;
        }

        &--bonus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 120%;
          opacity: 0;
          transition: opacity ease .25s;
          pointer-events: none;

          &.active {
            opacity: 1;
          }

          img {
            width: 100%;
            transform: scale(1.09);
          }
        }
      }
    }

    .tinder-buttons {
      display: flex;
      justify-content: center;
      padding: 55px 0;

    }

    button {
      cursor: pointer;
      border-radius: 50%;
      box-shadow: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      background-color: transparent;
      transition: all ease .25s;
      position: absolute;
      top: 56%;
      transform: translateY(-50%);
      @include media-breakpoint-down(sm) {
        top: 50%;
      }
      @include media-breakpoint-down(xs) {
        top: 60%;
        width: 50px;
        height: 50px;
        img {
          width: 50px !important;
          height: 50px !important;
        }
      }
      @media screen and (max-height: 675px) and (orientation: landscape) {
        top: 65%;
      }


      &#nope {
        left: 15px;
      }

      &#love {
        right: 15px;
      }

      &.d-none {
        display: none;
      }

      &:hover {
        opacity: .7;
      }

      img {
        border-radius: 100%;
        width: 64px;
        height: 64px;
        object-fit: cover;
        box-shadow: 7px 7px 5px rgba(0, 0, 0, .6);
      }

    }
  }

  @include media-breakpoint-down(sm) {
    //padding-top: 190px;
    padding-bottom: 0;
    min-height: 100vh;
    .inner {
      .block-tinder {
        padding-top: 13.3vw;

        .tinder-cards {
          height: 264px;

          .tinder-card {
            max-width: 264px;
            max-height: 264px;
          }
        }

        .tinder-buttons {
          padding: 64px 0 55px;
        }
      }
    }
  }
  @media screen and (max-height: 485px) and (orientation: landscape) {
    .inner {
      .block-tinder {
        padding-top: 4.3vw;
        padding-bottom: 13.3vw;
      }
    }
  }

}
