.header {
  position: absolute;
  //top: 6vh;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  min-height: 150px;
  width: 100%;
  transition: opacity 1.2s ease;
  opacity: 0;

  &.animated {
    opacity: 1;
  }
  &.disabled {
    opacity: 0;
  }
  &.overlap {
    opacity: 0;
  }
  &.active {
    &:before {
      opacity: 1;
    }
  }
  &.purple {
    &:after {
      opacity: 1;
    }
  }

  &:before {
    content: "";
    background: linear-gradient(180deg, rgba(253, 235, 44, 1) 80%, rgba(0, 0, 0, 0.0) 100%);
    opacity: 0;
    transition: opacity 500ms ease;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
  &:after {
    content: "";
    z-index: -1;
    background: linear-gradient(180deg, rgba(55, 9, 114, 1) 80%, rgba(0, 0, 0, 0.0) 100%);
    opacity: 0;
    transition: opacity 500ms ease;
    width: 100%;
    height: 120%;
    left: 0;
    top: 0;
    position: absolute;
  }

  .inner {
    margin: 0 auto;
    display: block;
    max-width: 408px;
    position: relative;

    will-change: opacity, transform, top;

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 3vh;
      max-width: 244px;
      margin: 0 auto;
      //transition: opacity 500ms ease;

      @include media-breakpoint-down(xs) {
        max-width: 254px;
      }
      &.blue {
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .inner {
      max-width: 320px;

      img {
        width: 100%;
      }
    }
  }
}
