.section4 {
  background: $c-yellow;
  position: relative;
  z-index: 1;
  display: none;

  .mouse {
    margin-top: 40px;
    position: relative;
    left: 0;
    bottom: 0;
    transform: none;

    &-shape {
      border-color: $c-purple;
    }

    &-animation {
      background: $c-purple;
    }
  }

  .block-animation-text {
    min-height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    .title {

    }

    @include media-breakpoint-down(xs) {
      min-height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .title {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .title {
    @extend .title-splite;
    @include fluid-type(375px, 991px, 40px, 56px);

    transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform .8s cubic-bezier(.165, .84, .44, 1);
    //opacity: 0;
    //transform: translateY(40px);
    top: 0 !important;
    position: relative !important;
    font-style: italic;
  }

  &.active {
    .title {
      //transform: translateY(0);
      //opacity: 1;
    }
  }
}

.block-metier {
  //padding-top: 10vw;
  padding-bottom: 80px;
  padding-left: 9vw;
  padding-right: 9vw;

  .inner {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;

    .title, .block-image, p {
      //opacity: 0;
      //transform: translateY(40px);
    }

    &.is-inview {
      .title, .block-image, p {
        opacity: 1;
        transform: translateY(0px);
      }
    }

    .title {
      text-align: center;
      font-family: $f-secondary-italic;
      font-style: italic;
      font-size: 76px;
      @include fluid-type(375px, 991px, 22px, 35px);
      line-height: 1.2;
      color: $c-purple;
      margin-bottom: 25px;
      transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform .8s cubic-bezier(.165, .84, .44, 1);
      overflow-wrap: break-word;
      hyphens: auto;
    }

    .block-image {
      text-align: center;
      margin-bottom: 20px;
      transition: opacity .6s cubic-bezier(.455, .03, .515, .955) .11s, transform .8s cubic-bezier(.165, .84, .44, 1) .11s;

      padding-left: 10vw;
      padding-right: 10vw;
      img {
        margin: 0 auto;
        display: block;
        max-width: 350px;
        width: 100%;
        height: auto;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      }
    }

    p {
      color: $c-purple;
      @include fluid-type(375px, 991px, 16px, 20px);
      font-family: $f-secondary;
      font-weight: $medium;
      line-height: 1.2;
      transition: opacity .6s cubic-bezier(.455, .03, .515, .955) .22s, transform .8s cubic-bezier(.165, .84, .44, 1) .22s;
    }
  }

  .scroll {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;

    opacity: 0;
    transition: opacity .6s cubic-bezier(.455, .03, .515, .955), transform .8s cubic-bezier(.165, .84, .44, 1);
    transform: translateY(-40px);

    &.is-inview {
      opacity: 1;
      transform: translateY(0);
    }

    p {
      color: $c-purple;
      margin-top: 20px;
      font-style: italic;
      //font-family: $f-secondary;
      font-family: $f-secondary-italic;
      font-size: 20px;
      text-transform: uppercase;
    }

    img {
      margin-top: 21px;
      width: 31px;
      height: auto;
      animation: bounce 0.6s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      @keyframes bounce {
        from {
          transform: translate3d(0, 0, 0);
        }
        to {
          transform: translate3d(0, 10px, 0);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .inner {
    }
    .scroll {
      margin-top: clamp(20px, 4vw, 150px);

      p {
        font-size: 30px;
        line-height: 1.2;
        @include fluid-type(375px, 768px, 16px, 30px);
      }

      img {
        width: 24px;
      }
    }

  }
  @include media-breakpoint-down(xs) {
    .inner {
      .title {
        margin-bottom: 10px;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .block-metier {
    padding-bottom: 40px;
  }
}
