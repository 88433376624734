footer {
  background: $c-purple;
  padding-top: 30px;
  padding-bottom: 30px;
  //display: none;

  .inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .block-image {
      position: relative;

      &:first-of-type {
        margin-right: 26px;
      }

      &:nth-of-type(2) {
        padding-left: 26px;
        padding-right: 26px;

        &:before, &:after {
          content: "";
          width: 1px;
          height: 40px;
          position: absolute;
          background: $c-white;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }

      &:last-of-type {
        margin-left: 26px;
      }
    }
  }

  .bottom {
    padding: 0 15px;

    a, p {
      font-size: 12px;
      color: $c-white;
    }

    p {
      font-family: "Arial", sans-serif;
      max-width: 800px;
      margin: 15px auto;
      text-align: center;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        font-family: "Arial", sans-serif;

        text-decoration: none;
        margin-right: 20px;
        position: relative;
        transition: box-shadow 500ms ease;
        box-shadow: 0 0px 0 inset white;

        &:before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          right: -11px;
          top: 50%;
          border-radius: 100%;
          background: $c-white;
          transform: translateY(-50%);
        }

        &:hover {
          box-shadow: 0 -1px 0 inset white;
        }
      }

      li {
        &:last-of-type {
          a {

            margin-right: 0;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .inner {
      padding: 0 15px;
      display: flex;
      flex-flow: row wrap;
      .block-image {
        flex: 0 0 calc(100%/3);
        max-width: calc(100%/3);
        text-align: center;
        margin: 0!important;
        padding: 0 10px!important;

        img {
          margin: 0 auto;
          display: block;
          max-width: 100%;
          height: auto;
          object-fit: contain;
        }
        &:nth-of-type(2) {
          &:before,&:after {
            top: 50%;
            height: 20px;
            transform: translateY(-50%);
          }
        }
      }
    }
    .bottom {
      p {
        margin: 24px 0;
      }
    }
  }

  .rs {
    list-style: none;
    margin-bottom: 10px;

    a {
      text-decoration: none!important;
      &:before, &:after {
        display: none;
      }
    }
    svg {
      width: 32px;
      height: 32px;

      path {
        fill: $c-white;
      }
    }
  }
}
