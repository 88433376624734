* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  &.ending {
    section {
      &:not(.section4) {
        display: none!important;
      }
    }
  }
}

.overflow-tinder {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  &.italic {
    #futur-metier .inner .title, .title-splite {
      font-style: italic;
    }
  }
}

.break-responsive {
  @media (min-width: 769px) {
    display: none;
  }
}
